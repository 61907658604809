import {
    getLogFilterData,
    getSelectedLog,
    getSelectedLogNameItem,
    resetLogFilterData,
    setAppActions,
    setAppInstances,
    setAppResources,
    setSelectedLog,
} from 'hooks/useOrganizationLoggingHook';
import { processResponse, sendGetRequest } from './dataRequestUtils';
import { getCurrentOrganizationId } from './loginUtils';
import { showSnackBarErrorNotification } from './snackBarNotificationUtils';
import {
    getAllApps,
    getAppInterfaceResourceActionsById,
    getAppInterfaceResourcesByInterfaceId,
} from './appRequestUtils';

const ignoreErrorCodes = [404];

/**
 *
 * @param organizationId
 * @param {function} successCallback
 * @param {function} errorCallback
 * @param {function} finallyCallback
 * @param {{}} data
 * @returns {Promise<*>}
 */
export const getMappingExecutionLogsByOrganization = ({
    organizationId,
    successCallback,
    errorCallback,
    finallyCallback,
    data,
}) =>
    sendGetRequest({
        url: `/logging/mappings/${organizationId}`,
        data,
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            errorMessage: '',
            ignoreErrorCodes,
        }),
    });

/**
 * Get Mapping log by log ID
 * @param {{}} args
 * @returns {Promise<*>} response
 */
export const getMappingExecutionLogByLogId = ({
    mappingLogId,
    successCallback,
    errorCallback,
    finallyCallback,
    errorMessage = '',
    data = {},
}) =>
    sendGetRequest({
        url: `/logging/mappings/${getCurrentOrganizationId()}/log/${mappingLogId}`,
        data,
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            errorMessage,
            ignoreErrorCodes,
        }),
    });

/**
 * Get API call logs by org ID
 * @param {Object} args
 * @return {Promise<any>} response
 */
export const getApiCallLogsByOrgId = ({
    organizationId,
    successCallback,
    errorCallback,
    finallyCallback,
    data = {},
} = {}) =>
    sendGetRequest({
        url: `/logging/getApiCallByOrgId/${organizationId}`,
        data,
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            errorMessage: '',
            ignoreErrorCodes,
        }),
    });

/**
 * Get API call log by log ID
 * @param {{}} args
 * @param {string} args.apiCallLogId
 * @param {function} args.successCallback
 * @param {function} args.errorCallback
 * @param {function} args.finallyCallback
 * @param {string} args.errorMessage
 * @param {{}} args.data
 * @returns {Promise<any>} response
 */
export const getApiCallLogByLogId = ({
    apiCallLogId,
    successCallback,
    errorCallback,
    finallyCallback,
    errorMessage = '',
    data = {},
} = {}) =>
    sendGetRequest({
        url: `/logging/getApiCallById/${apiCallLogId}`,
        data,
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            errorMessage,
            ignoreErrorCodes,
        }),
    });

/**
 * Get app request logs by org ID
 * @param {Object} args
 * @return {Promise<any>} response
 */
export const getAppRequestLogsByOrgId = ({
    organizationId,
    successCallback,
    errorCallback,
    finallyCallback,
    data,
} = {}) =>
    sendGetRequest({
        url: `/logging/getAppReqCallByOrgId/${organizationId}`,
        data,
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            errorMessage: '',
            ignoreErrorCodes,
        }),
    });

/**
 * Get App Request log by log ID
 * @param {{}} args
 * @param {string} args.appRequestLogId
 */
export const getAppRequestLogByLogId = ({
    appRequestLogId,
    successCallback,
    errorCallback,
    finallyCallback,
    errorMessage = '',
    data = {},
} = {}) =>
    sendGetRequest({
        url: `/logging/getAppReqCallByLogId/${appRequestLogId}`,
        data,
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            errorMessage,
            ignoreErrorCodes,
        }),
    });

/**
 *
 * @param organizationId
 * @param successCallback
 * @param errorCallback
 * @param finallyCallback
 * @param data
 * @returns {Promise<*>}
 */
export const getComparisonExecutionLogsByOrganization = ({
    organizationId,
    successCallback,
    errorCallback,
    finallyCallback,
    data,
} = {}) =>
    sendGetRequest({
        url: `/logging/comparisons/${organizationId}`,
        data,
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            errorMessage: '',
            ignoreErrorCodes,
        }),
    });

/**
 *
 * @param {{}} param
 * @returns {Promise<*>}
 */
export const getComparisonExecutionLogByLogId = ({
    comparisonLogId,
    successCallback,
    errorCallback,
    finallyCallback,
    errorMessage = '',
    data = {},
} = {}) =>
    sendGetRequest({
        url: `/logging/comparisons/${getCurrentOrganizationId()}/log/${comparisonLogId}`,
        data,
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            errorMessage,
            ignoreErrorCodes,
        }),
    });

/**
 *
 * @param organizationId
 * @param successCallback
 * @param errorCallback
 * @param finallyCallback
 * @param data
 * @returns {Promise<*>}
 */
export const getIntegrationExecutionLogsByOrganization = ({
    organizationId,
    successCallback,
    errorCallback,
    finallyCallback,
    data,
} = {}) =>
    sendGetRequest({
        url: `/logging/integrations/${organizationId}`,
        data,
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            ignoreErrorCodes,
        }),
    });

/**
 * Get Integration log by log ID
 * @param {{}} args
 * @returns {Promise<*>} response
 */
export const getIntegrationExecutionLogByLogId = ({
    integrationLogId,
    successCallback,
    errorCallback,
    finallyCallback,
    errorMessage = '',
    data = {},
} = {}) =>
    sendGetRequest({
        url: `/logging/integrations/${getCurrentOrganizationId()}/log/${integrationLogId}`,
        data,
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            errorMessage,
            ignoreErrorCodes,
        }),
    });

/**
 * Data request args helper
 * @param {state} object
 */
const dataRequestArgs = (state = {}) => ({
    successCallback: (res) => {
        // console.log('log req: ', res?.data);

        setSelectedLog({
            ...state,
            loading: false,
            data: res?.data || {},
            isInitRequestDone: true,
        });
    },
    errorCallback: () => {
        showSnackBarErrorNotification('Unable to fetch logs');
        setSelectedLog({
            loading: false,
            data: {},
            isInitRequestDone: true,
        });
    },
});

/**
 * Get logs by type
 * @param {{ logType: string, data: object, organizationId: string,  }}
 * @returns {Promise<*>}
 */
export const getLogsByType = async ({ logType, organizationId, data = {}, state = {} }) => {
    if (!logType || !organizationId) return;

    setSelectedLog({
        data: {},
        loading: true,
        ...state,
    });

    const args = {
        data,
        organizationId,
        ...dataRequestArgs(state),
    };

    // API request logs
    if (logType === 'api') return getApiCallLogsByOrgId(args);

    // App request logs
    if (logType === 'app-request') return getAppRequestLogsByOrgId(args);

    // Comparison execution logs
    if (logType === 'comparison') return getComparisonExecutionLogsByOrganization(args);

    // Mapping logs
    if (logType === 'mappings') return getMappingExecutionLogsByOrganization(args);

    // Integration logs
    if (logType === 'integrations') return getIntegrationExecutionLogsByOrganization(args);
};

/**
 * @param logType
 * @returns {{startTime: number}|{executedAt: number}|null}
 */
export const getDefaultSortForLogType = (logType) => {
    switch (logType) {
        case 'api':
        case 'app-request':
            return { startTime: -1 };
        case 'comparison':
        case 'mappings':
        case 'integrations':
            return { executedAt: -1 };
    }

    return null;
};

/**
 * Get initial log type after user is logged in
 * @param {string} organizationId
 */
export const initGetLogsRequest = async (organizationId) => {
    const selectedLogType = getSelectedLogNameItem(),
        selectedLog = getSelectedLog();

    if (selectedLog?.isInitRequestDone) return;

    getLogsByType({
        organizationId,
        logType: selectedLogType?.name,
        isInitRequestDone: true,
        data: {
            pageNo: 1,
            sorts: getDefaultSortForLogType(selectedLogType?.name),
        },
    });
};

/**
 * @typedef SearchState
 * @property {number} pageNo
 * @property {number} limit
 * @property {Object} state
 * @property {Object|null} filters
 * @property {Object} sorts
 */

/**
 * Helper to for fetching the logs in component context
 * Note: searchText has been deprecated
 *
 * @param {SearchState} args
 * @returns {Promise<void>}
 */
export const fetchLogs = async ({
    pageNo = 1,
    limit = 10,
    state = {},
    filters = null,
    sorts = {},
    clearSearchFilters,
} = {}) => {
    const selectedLogType = getSelectedLogNameItem(),
        logFilterData = getLogFilterData(),
        hasFilters =
            logFilterData?.logName || logFilterData?.logStatus || logFilterData?.startDate || logFilterData?.searchText;

    const stateObj = {
        ...state,
        hasSearchResults: !!hasFilters,
    };

    const data = {
        sorts,
        filters: filters || logFilterData,
        pageNo,
        limit,
    };

    if (clearSearchFilters) {
        data.filters = {};
        stateObj.hasSearchResults = false;
        resetLogFilterData();
    }

    await getLogsByType({
        state: stateObj,
        organizationId: getCurrentOrganizationId(),
        logType: selectedLogType?.name,
        data,
    });
};

/**
 * Fetch apps for app request log filters
 */
export const fetchApps = async () => {
    getAllApps({
        successCallback: (res) => {
            setAppInstances(res.data.results);
        },
    });
};

/**
 * Fetch app resources
 * @param {{ appId: string, interfaceId: string }} args
 */
export const fetchAppResources = async ({ appId, interfaceId }) => {
    getAppInterfaceResourcesByInterfaceId({
        appId,
        interfaceId,
        successCallback: (res) => {
            setAppResources(res.data);
        },
    });
};

/**
 * Fetch app actions
 * @param {{ appId: string, interfaceId: string, resourceId: string }} args
 */
export const fetchAppActions = async ({ appId, interfaceId, resourceId }) => {
    getAppInterfaceResourceActionsById({
        appId,
        interfaceId,
        resourceId,
        successCallback: (res) => {
            setAppActions(res.data);
        },
    });
};
