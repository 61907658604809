import { Typography } from '@mui/material';
import { getSelectedLogNameItem, setViewLogRequest } from 'hooks/useOrganizationLoggingHook';
import { descriptionTableColumnData, nameTableColumnData } from 'utils/tableUtils';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import DescriptionTooltip from 'components/Tooltips/DescriptionTooltip';
import ResponsesStatus from 'components/ResponseStatus';
import {
    getApiCallLogByLogId,
    getAppRequestLogByLogId,
    getComparisonExecutionLogByLogId,
    getIntegrationExecutionLogByLogId,
    getMappingExecutionLogByLogId,
} from 'utils/loggingUtils';
import dayjs from 'dayjs';
import { DATE_FORMAT_WITH_TIME } from '../../../../config/constants';

/**
 * Render date
 * @param {string} column
 * @returns {function}
 */
const renderDate = (column) => (row) => {
    const date = dayjs(row[column]);
    if (date.isValid()) {
        return date.format(DATE_FORMAT_WITH_TIME);
    }

    return '-';
};

/**
 * Log action buttons
 * @param {bool} isTooltip
 * @param {object} row
 * @returns {React.Component}
 */
const renderLogId = (isTooltip, row) => (
    <Typography className="logIdField" style={{ fontSize: '13px !important' }}>
        {isTooltip ? 'Log ID: ' : ''} {row?._id}
    </Typography>
);

/**
 *
 * @param {{ row: object, view: 'request'|'response' }} param0
 */
const handleViewLogRequestAction =
    ({ row, view, title }) =>
    () => {
        const selectedLogNameItem = getSelectedLogNameItem();
        const dataToSend = {
            ...(view === 'request' ? { includeRequestBody: true } : {}),
            ...(view === 'response' ? { includeResponseBody: true } : {}),
        };
        switch (selectedLogNameItem?.name) {
            case 'api':
                getApiCallLogByLogId({
                    apiCallLogId: row._id,
                    data: dataToSend,
                    successCallback: (response) => {
                        setViewLogRequest({
                            open: true,
                            data: { ...response.data, params: response.data.queryParams || {} },
                            view,
                            title,
                        });
                    },
                    errorMessage: 'Failed to fetch log details',
                });
                break;
            case 'app-request':
                getAppRequestLogByLogId({
                    appRequestLogId: row._id,
                    data: dataToSend,
                    successCallback: (response) => {
                        setViewLogRequest({
                            open: true,
                            data: response.data,
                            view,
                            title,
                        });
                    },
                });
                break;
            case 'comparison':
                getComparisonExecutionLogByLogId({
                    comparisonLogId: row._id,
                    data: dataToSend,
                    successCallback: (response) => {
                        setViewLogRequest({
                            open: true,
                            data: response.data.results[0],
                            view,
                            title,
                        });
                    },
                });
                break;
            case 'mappings':
                getMappingExecutionLogByLogId({
                    mappingLogId: row._id,
                    data: dataToSend,
                    successCallback: (response) => {
                        setViewLogRequest({
                            open: true,
                            data: response.data.results[0],
                            view,
                            title,
                        });
                    },
                });
                break;
            case 'integrations':
                getIntegrationExecutionLogByLogId({
                    integrationLogId: row._id,
                    data: dataToSend,
                    successCallback: (response) => {
                        setViewLogRequest({
                            open: true,
                            data: response.data.results[0],
                            view,
                            title,
                        });
                    },
                });
                break;
            default:
                break;
        }
    };

/**
 * Log action buttons
 * @param {bool} isTooltip
 * @param {object} row
 * @returns {React.Component}
 */
const renderViewLogActionButtons = (isTooltip = false, row) => (
    <div className="actionButtons" style={{ marginTop: isTooltip ? '10px' : 0 }}>
        <PrimaryButton
            height="30px"
            padding="0px 7px"
            fontSize="12px"
            width="auto"
            margin="0px 5px 5px 0px"
            borderRadius="3px"
            color="primary"
            backgroundColor="#fff"
            className="viewRequestBtn"
            onClick={handleViewLogRequestAction({ row, view: 'request', title: 'View Request' })}
        >
            View Request
        </PrimaryButton>
        <PrimaryButton
            height="30px"
            padding="0px 7px"
            color="primary"
            backgroundColor="#fff"
            fontSize="12px"
            width="auto"
            margin="0px 0px 5px 0px"
            borderRadius="3px"
            onClick={handleViewLogRequestAction({ row, view: 'response', title: 'View Response' })}
        >
            View Response
        </PrimaryButton>
    </div>
);

/**
 * Table Columns
 * @param {Object} props List of properties to apply to the column
 * @param {Object} props.navigate useNavigate() object
 * @returns {Array<React.Element>}
 */
const TableColumns = () => {
    const selectedLogNameItem = getSelectedLogNameItem(),
        logNameColumn = {
            grow: 1.6,
            title: 'Integration Workflow Name',
            fieldName: 'integrationWorkflowName',
            extraColumns: [],
            sortable: false,
        };

    let actionColumnGrow = 2.2;

    switch (selectedLogNameItem?.name) {
        case 'comparison':
            logNameColumn.grow = 1.5;
            logNameColumn.title = 'Name';
            logNameColumn.fieldName = 'comparisonName';
            logNameColumn.sortable = true;
            logNameColumn.extraColumns = [
                descriptionTableColumnData({
                    grow: 1,
                    name: 'Executed By',
                    fieldName: 'executedBy',
                    sortable: true,
                }),
                descriptionTableColumnData({
                    grow: 1,
                    name: 'Executed At',
                    fieldName: 'executedAt',
                    renderCallback: renderDate('executedAt'),
                    sortable: true,
                }),
                descriptionTableColumnData({
                    grow: 1,
                    name: 'Execution Time',
                    fieldName: 'executionTime',
                    renderCallback: (row) => {
                        const executionEndedAt = new Date(Date.parse(row.executionEndedAt)),
                            executedAtTime = new Date(Date.parse(row.executedAt)),
                            diffTime = Math.abs(executionEndedAt.getTime() - executedAtTime.getTime());
                        let timeRan;

                        if (diffTime >= 10000) {
                            timeRan = (diffTime / 1000).toFixed(2) + ' sec';
                        } else {
                            timeRan = diffTime + ' ms';
                        }

                        return (
                            <Typography component="div" className="logIdField">
                                {timeRan}
                            </Typography>
                        );
                    },
                }),
            ];
            break;
        case 'mappings':
            logNameColumn.grow = 1.5;
            logNameColumn.title = 'Name';
            logNameColumn.fieldName = 'mappingName';
            logNameColumn.sortable = true;
            logNameColumn.extraColumns = [
                descriptionTableColumnData({
                    grow: 1,
                    name: 'Executed By',
                    fieldName: 'executedBy',
                    sortable: true,
                }),
                descriptionTableColumnData({
                    grow: 1,
                    name: 'Executed At',
                    fieldName: 'executedAt',
                    renderCallback: renderDate('executedAt'),
                    sortable: true,
                }),
            ];
            break;
        case 'integrations':
            logNameColumn.grow = 1.5;
            logNameColumn.title = 'Name';
            logNameColumn.fieldName = 'integrationName';
            logNameColumn.sortable = true;
            logNameColumn.extraColumns = [
                descriptionTableColumnData({
                    grow: 1,
                    name: 'Executed By',
                    fieldName: 'executedBy',
                    sortable: true,
                }),
                descriptionTableColumnData({
                    grow: 1,
                    name: 'Executed At',
                    fieldName: 'executedAt',
                    renderCallback: renderDate('executedAt'),
                    sortable: true,
                }),
            ];
            break;
        case 'api':
            logNameColumn.grow = 2;
            logNameColumn.title = 'Route';
            logNameColumn.fieldName = 'route';
            logNameColumn.sortable = true;
            logNameColumn.extraColumns = [
                descriptionTableColumnData({
                    grow: 1,
                    name: 'IP Address',
                    fieldName: 'ipAddress',
                    renderCallback: (row) => (
                        <Typography component="div" className="logIdField">
                            {row.ipAddress || '—'}
                        </Typography>
                    ),
                    sortable: true,
                }),
                descriptionTableColumnData({
                    grow: 1.2,
                    name: 'Start Time',
                    fieldName: 'startTime',
                    renderCallback: renderDate('startTime'),
                    sortable: true,
                }),
                descriptionTableColumnData({
                    grow: 1.2,
                    name: 'End Time',
                    fieldName: 'endTime',
                    renderCallback: renderDate('endTime'),
                    sortable: true,
                }),
            ];
            break;

        case 'app-request':
            actionColumnGrow = 1.5;

            logNameColumn.grow = 1;
            logNameColumn.title = 'App Name';
            logNameColumn.fieldName = 'appName';
            logNameColumn.sortable = true;
            logNameColumn.extraColumns = [
                descriptionTableColumnData({
                    grow: 1,
                    name: 'Interface',
                    fieldName: 'interfaceName',
                    renderCallback: (row) => (
                        <Typography component="div" className="logIdField">
                            {row.interfaceName || '—'}
                        </Typography>
                    ),
                    sortable: true,
                }),
                descriptionTableColumnData({
                    grow: 1.2,
                    name: 'App Instance',
                    fieldName: 'appInstanceName',
                    renderCallback: (row) => (
                        <Typography component="div" className="logIdField">
                            {row.appInstanceName || '—'}
                        </Typography>
                    ),
                    sortable: true,
                }),
                descriptionTableColumnData({
                    grow: 1,
                    name: 'Resource',
                    fieldName: 'resourceName',
                    renderCallback: (row) => (
                        <Typography component="div" className="logIdField">
                            {row.resourceName || '—'}
                        </Typography>
                    ),
                    sortable: true,
                }),
                descriptionTableColumnData({
                    grow: 1.2,
                    name: 'Action Name',
                    fieldName: 'actionName',
                    renderCallback: (row) => (
                        <Typography component="div" className="logIdField">
                            {row.actionName || '—'}
                        </Typography>
                    ),
                    sortable: true,
                }),
                descriptionTableColumnData({
                    grow: 1.2,
                    name: 'Start Time',
                    fieldName: 'startTime',
                    renderCallback: renderDate('startTime'),
                    sortable: true,
                }),
                descriptionTableColumnData({
                    grow: 1.2,
                    name: 'End Time',
                    fieldName: 'endTime',
                    renderCallback: renderDate('endTime'),
                    sortable: true,
                }),
            ];
            break;

        default:
            break;
    }

    return [
        descriptionTableColumnData({
            grow: 2.3,
            name: 'Log ID',
            fieldName: '_id',
            tooltipProps: false,
            renderCallback: (row) => (
                <DescriptionTooltip
                    title={
                        <div className="logTooltip">
                            {renderLogId(true, row)}
                            {renderViewLogActionButtons(true, row)}
                        </div>
                    }
                    text={renderLogId(false, row)}
                    tooltipProps={{ placement: 'right' }}
                    rootStyle={{ marginTop: '25px' }}
                />
            ),
            sortable: true,
        }),
        descriptionTableColumnData({
            grow: logNameColumn.grow,
            name: logNameColumn.title,
            fieldName: logNameColumn.fieldName,
            renderCallback: (row) => (
                <Typography component="div" className="logIdField">
                    {row[logNameColumn.fieldName] || '—'}
                </Typography>
            ),
            sortable: logNameColumn.sortable,
        }),
        ...logNameColumn.extraColumns,
        descriptionTableColumnData({
            grow: 0.5,
            name: 'Status',
            fieldName: 'statusCode',
            renderCallback: (row) => <ResponsesStatus statusCode={row?.statusCode || 0} />,
            sortable: true,
        }),
        nameTableColumnData({
            grow: actionColumnGrow,
            name: 'Actions',
            fieldName: '_id',
            renderCallback: (row) => renderViewLogActionButtons(false, row),
            sortable: false,
        }),
    ];
};

export default TableColumns;
